import React, { useState } from "react";
import { useFormInput } from "../hooks/useFormInput";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import PageWrapper from "../components/PageWrapper";
import Loading from "../components/Loading";
import imgBg from "../assets/image/inner-page/jpg/contact3.jpg";

const Contact = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [feedback, setFeedback] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const email = useFormInput("");
  const company = useFormInput("");
  const firstName = useFormInput("");
  const lastName = useFormInput("");
  const consent = useFormInput(false);

  const emailVal = email.value;
  const companyVal = company.value;
  const firstNameVal = firstName.value;
  const lastNameVal = lastName.value;
  const consentVal = consent.value;

  const handleSubmit = async (e) => {
    e.preventDefault(); //--> prevent the page from reloading on form submit
    setLoading(true);
    // Check if the captcha was skipped or not
    if (!executeRecaptcha) {
      return;
    }

    // This is the same as grecaptcha.execute on traditional html script tags
    const result = await executeRecaptcha("homepage");

    // Prepare the data for the server, specifically body-parser
    const data = JSON.stringify({
      email: emailVal,
      company: companyVal,
      firstName: firstNameVal,
      lastName: lastNameVal,
      token: result,
    });

    fetch("https://app.timeview.io/api/companies/requestinfo", {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-type": "application/json",
      },
      body: data, //--> this contains your data
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(
            "Ha ocurrido un error, intentálo más tarde o contacta con nosotros a través de hola@timeview.io"
          );
        }

        return res.json();
      })
      .then((data) => {
        setLoading(false);
        setError("");
        setFeedback(
          "¡Hemos recibido tu solicitud! Pronto nos pondremos en contacto contigo."
        );
      })
      .catch((error) => {
        setLoading(false);
        setFeedback("");
        setError(error?.message);
      });
  };

  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "pt-13 pt-lg-11 px-14 px-xl-12",
          headerLogoClassName: "mx-auto mx-lg-0",
        }}
      >
        <div className="min-height-100vh d-flex align-items-center bg-default-3">
          <div className="container-fluid h-100 p-0">
            <div className="row no-gutters align-items-center justify-content-center h-100">
              <div className="col-xl-4 col-lg-6 col-md-10">
                {loading && <Loading background="rgb(247 248 250 / 48%)" />}
                <div className="pt-23 pt-md-17 pt-lg-18 pb-md-4 pb-lg-10 max-w-413 mx-auto">
                  <div
                    className="mb-10"
                    data-aos="fade-up"
                    data-aos-duration={500}
                    data-aos-delay={500}
                  >
                    <h6 className="font-size-5 text-buttercup mb-5 mb-lg-5 font-weight-normal">
                      Resolveremos todas tus dudas
                    </h6>
                    <h2 className="mb-2 font-size-10 letter-spacing-n83">
                      ¿Hablamos?
                    </h2>
                    <p className="text-bali-gray font-size-7 mb-0">
                      Nuestro equipo se pondrá en contacto contigo
                    </p>
                  </div>
                  <form
                    className="register-form"
                    action="/"
                    data-aos="fade-up"
                    data-aos-duration={800}
                    data-aos-delay={500}
                    onSubmit={handleSubmit}
                  >
                    {/* Email */}
                    <div className="form-group mb-6 position-relative">
                      <input
                        value={emailVal}
                        onChange={email.onChange}
                        type="email"
                        name="email"
                        className="form-control form-control-md bg-white rounded-4 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5"
                        placeholder="Email*"
                        id="email"
                        required
                      />
                    </div>
                    {/* Company Name */}
                    <div className="form-group mb-6 position-relative">
                      <input
                        value={companyVal}
                        onChange={company.onChange}
                        type="text"
                        name="company"
                        className="form-control form-control-md bg-white rounded-4 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5"
                        placeholder="Empresa*"
                        id="company"
                        required
                      />
                    </div>
                    {/* First Name & Last Name */}
                    <div className="form-group form-group-inline mb-6 position-relative">
                      <input
                        value={firstNameVal}
                        onChange={firstName.onChange}
                        type="text"
                        name="firstName"
                        className="form-control form-control-md bg-white rounded-4 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 mr-6"
                        placeholder="Nombre*"
                        id="firstName"
                        required
                      />
                      <input
                        value={lastNameVal}
                        onChange={lastName.onChange}
                        type="text"
                        name="lastName"
                        className="form-control form-control-md bg-white rounded-4 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5"
                        placeholder="Apellidos*"
                        id="lastName"
                        required
                      />
                    </div>
                    {/* Terms */}
                    <div className="form-group mb-6 position-relative text-bali-gray font-size-3">
                      <input
                        checked={consentVal}
                        onChange={consent.onChange}
                        type="checkbox"
                        name="consent"
                        className="bg-white rounded-4 text-dark-cloud text-placeholder-bali-gray font-size-5 mr-2"
                        id="consent"
                        required
                      />
                      He leído y consiento al contenido de la{" "}
                      <a href="/legal">Información Legal</a> y de la{" "}
                      <a href="/privacy">Política de Privacidad</a>,
                      consintiendo expresamente al tratamiento de mis datos
                      personales conforme a su contenido.
                    </div>
                    <div className="button">
                      <button
                        type="submit"
                        className="btn btn-blue-3 w-100 rounded-4"
                        disabled={!consentVal}
                      >
                        Enviar
                      </button>
                      <p className="mt-5 text-bali-gray font-size-3">
                        Esta página está protegida por el reCAPTCHA. Se aplica
                        la{" "}
                        <a
                          href="https://policies.google.com/privacy"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Política de Privacidad
                        </a>{" "}
                        de Google y los{" "}
                        <a
                          href="https://policies.google.com/terms"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Términos del Servicio
                        </a>
                        .
                      </p>
                      {feedback && (
                        <h6 className="font-size-5 text-green mb-5 mb-lg-9 mt-5 font-weight-normal">
                          {feedback}
                        </h6>
                      )}
                      {error && (
                        <h6 className="font-size-5 text-red mb-5 mb-lg-9 mt-5 font-weight-normal">
                          {error}
                        </h6>
                      )}
                    </div>
                  </form>
                </div>
              </div>
              {/* Right Image */}
              <div className="col-xl-8 col-lg-6 col-md-10 min-height-lg-100vh">
                <div
                  className="bg-images min-height-100vh d-none d-lg-block"
                  css={`
                    background-image: url(${imgBg});
                  `}
                />
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default Contact;
